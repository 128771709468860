import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Home from './components/pages/Home';
import Modelo from './components/pages/Modelo/Modelo';
import Footer from './components/Footer/Footer';
import HelperAvatar from './components/HelperAvatar/HelperAvatar';
import { API_KEY } from './constants';
import axios from 'axios';
import Loading from './components/Loading/Loading';

function App() {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
    let sheet = "WEBPAGE"
    axios.get(`https://sheets.googleapis.com/v4/spreadsheets/1FyBkFmdLO8BeNdmDohYRvAh_nJP1jsdsEZ_rPYm8m1s/values/${sheet}?key=${API_KEY}`)
        .then(res => {
            let data1 = res.data.values;
            setData(data1)
        })
  },[])
  if(data.length > 0){
    return (
      <>
        <Router>
          <Navbar data={data}/>
          <Routes>
            <Route path='/' exact element={<Home data={data}/>}/>
          </Routes>
          <Footer />
          <HelperAvatar data={data} />
        </Router>
      </>
    );
  }else
    return <></>
}

export default App;
