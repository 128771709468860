import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="row">
                    <div className="col-5">
                        <h1 className='float-left'>Estámos para ayudarte</h1>
                        <h2 className='button float-left'><a href='mailto:gabriel.morett@yodesarrollo.mx'>Contáctanos</a></h2>
                    </div>
                    <div className="col-5 social-links padding-30">
                        <a href="https://www.facebook.com/Yo-Desarrollo-103075448823440/" target="_blank" rel='noreferrer'>
                            Facebook
                        </a>
                        <a href="https://www.instagram.com/yodesarrollo" target="_blank" rel='noreferrer'>
                            Instagram
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5216623184512" target="_blank" rel='noreferrer'>
                            WhatsApp
                        </a>
                    </div>
                </div>
                <div className="row section-2">
                    <div className="col col-5">
                        <div className="footer-text text-note">
                            Yo Desarrollo se reserva el derecho de realizar modificaciones en los planos, diseños exteriores, precios, materiales, especificaciones, acabados y características en cualquier momento y sin previo aviso. Las fotografías e imágenes son solo un concepto ilustrativo.
                        </div>
                    </div>
                    <div className="col col-5 logos-section">
                        <img src='https://ramico.com.mx/img/logo_fox.png' />
                        <img src='https://static.wixstatic.com/media/357077_21db56f1ce3e40819c312a3f772364c9~mv2.png/v1/crop/x_893,y_1275,w_2656,h_1823/fill/w_265,h_182,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SinFondo.png' />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-10">
                        <div className="footer-text">
                            <p>Yodesarrollo.mx</p>
                            <p>Desarrollador por <a href="https://ramico.com.mx"><span className="ra">ra</span><span className="mi">mi</span><span className="co">co</span></a></p>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Footer