import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection/HeroSection';


export default function Home({data}) {
    return (
        <>
            <HeroSection data={data}/>
        </>
    )
}
