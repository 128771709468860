import React from 'react';
import './HeroSection.css';
import parse from 'html-react-parser';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ModalFormInversion from '../ModalFormInversion/ModalFormInversion';
import Loading from '../Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faTiktok } from "@fortawesome/free-brands-svg-icons"
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Snackbar } from '@mui/material';
import axios from 'axios';

const initValues = {
    question1: "",
    question2: "",
    name: "",
    email: "",
    phone: "",
}

function HeroSection({data}) {
    const [dataInversion, setDataInversion] = React.useState([])
    const [dataInversionSelected, setDataInversionSelected] = React.useState([])
    const [dataForm, setDataForm] = React.useState(initValues)
    const [rangoInversion, setRangoInversion] = React.useState([])
    const [background, setBackground] = React.useState('linear-gradient(90deg, rgb(27 27 27) 20%, rgb(204, 204, 204) 169%)')
    const [openModal, setOpenModal] = React.useState(false)
    const [posicion, setPosicion] = React.useState({})
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [messageAlert, setMessageAlert] = React.useState("");

  const handleClickOpen = () => {
    setDataForm(initValues)
    setMessageAlert("")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDataForm({})
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const sendEmail = async () => {
    if(Object.keys(dataForm).length === 0 || (dataForm.question1=="" || dataForm.question2=="" || dataForm.name=="" || dataForm.email=="" || dataForm.phone=="") ){
        setOpenSnack(true)
        setMessageAlert("Necesita llenar todos los campos")
        return
    }else{
        //send api
        let result = await axios.post("https://apifutbol.ramico.com.mx/index.php/inversion/",{dataForm})
        // console.log("result")
        // console.log(result)
        setOpenSnack(true)
        setMessageAlert("Envíado el correo exitosamente")
        handleClose()
    }
  }

    const dataForInversion = (data) => {
        var inversion = [];
        let indexInversion = 1;
        var i = 0;
        var j = 28;
        while ( data[j][i] != undefined){
            inversion.push({
                casa: data[j][i],
                inicioInversion: Number(data[114][indexInversion].replace(/[^0-9.-]+/g,"")),
                topeInversion: Number(data[115][indexInversion]?.replace(/[^0-9.-]+/g,"")),
                tituloBoton: data[116][indexInversion],
                descripcion: data[117][indexInversion],
                sendEmailTo: data[118][indexInversion],
                sendSMSTo: data[122][indexInversion]
            })
            indexInversion++;
            i+=3
        }
        setDataInversion(inversion);
    }
    const rangosInversion = (data) => {
        var rango = [];
        var i = 1;
        var j = 119;
        while ( data[j][i] != undefined){
            rango.push({
                porcentaje: data[j][i],
                cantidad: Number(data[j+1][i].replace(/[^0-9.-]+/g,""))
            })
            i++
        }
        setRangoInversion(rango);
    }


    React.useEffect(() => {
        if(data.length>0){
            dataForInversion(data);
            rangosInversion(data)
        }
    }, [data])

    const handleDragStart = (e) => e.preventDefault();

    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    const openFormInversion = async (nombreCasa,e) => {
        const screen = document.createElement('div');
        // ✅ Add classes to element
        screen.classList.add('modal-screen');
        document.body.prepend(screen)
        document.body.style = "overflow: hidden;"
        await setOpenModal(false)
        let pos = posicion
        pos.left = (e.clientX - 100) + 'px'
        pos.top = e.clientY + 'px'
        setPosicion(pos)
        setOpenModal(true)
        let selected = dataInversion.find((i)=>{ return i.casa === nombreCasa})
        setDataInversionSelected(selected)
    }

    const closeFormInversion = () => {
        document.body.style = ""
        document.getElementsByClassName("modal-screen")[0].remove()
        setOpenModal(false)
    }

    const NuestrosProductos = () => {
        let row = []
        var i = 0;
        var j = 28;
        const responsive = {
            0: { items: 1 },
            760: { items: 2 },
            1200: { items: 2 },
            1600: { items: 3 },
        };
        let cell = 0;
        while ( data[j][i] != undefined){
            let aux = []
            let nomCasa = data[j][i]
            {(data[j][i+1]) ? aux.push(<img src={`https://drive.google.com/thumbnail?id=${data[j][i+1].split('/')[5]}&sz=w1000`} alt={"Proyecto"} width={"100%"} height={300} className='img-proyecto'/>) : aux.push( <></>)}
            row.push(
                <div key={i} className='content-card no-border no-shadow'>
                    <div className='image'>
                        {(data[j][i+2]) ? 
                            <a href={(data[j][i+2])}> 
                                {aux.pop()}
                            </a>
                            :
                            aux.pop()
                        }
                    </div>
                    <div className='description'>
                        <div className='row'>
                            <div className='col-3-reverse'>
                                <h2>{data[j][i]}</h2>
                            </div>
                            <div className='col-3 text-right button-investment'>
                                {/*inversion: 
                                Si: Se abre modal de registro
                                No o vacío: No se muestra botón
                                Completa: Se muestra texto de inversión + completa */} 
                                {data[j+4][cell+2]=='si' ? 
                                    <p><a href={data[j+4][cell] /* "#" */} target={'_blank'}  /* onClick={handleClickOpen} */ className='button float-right padding-5-20' >{ data[j+4][cell+1]}</a></p> 
                                : <></>}
                                {data[j+4][cell+2]=='completa' ? <p className='inversion-completa float-right padding-5-20'>{ data[j+4][cell+2]}</p> : <></>}
                            </div>
                        </div>
                        <div className='links-card'>
                            {data[j+2][cell+2] ? <p><a href={ data[j+2][cell+2] } >{ data[j+2][cell+1]}</a></p> : <></>}
                            {data[j+3][cell+2] ? <p><a href={ data[j+3][cell+2] } >{ data[j+3][cell+1]}</a></p> : <></>}
                        </div>
                    </div>
                </div>
            )
            cell+=3
            i+=3
        }
        return  <AliceCarousel responsive={responsive} infinite={true}  mouseTracking controlsStrategy={"alternate"} items={row} />;
    }

    const Conocenos = () => {
        let row = []
        var i = 0;
        var j = 37;
        const responsive = {
            0: { items: 1 },
            568: { items: 1 },
            600: { items: 2 },
            1024: { items: 4 },
        };
        while ( data[j][i+1] != undefined){
            row.push(
                <div key={i} className='content-card-conocenos'>
                    <div className='image'>{data[j+1][i + 1] !==undefined ? <img src={`https://drive.google.com/thumbnail?id=${data[j + 1][i + 1].split('/')[5]}&sz=w1000`} alt={"Logo"} width={"100%"} /> : <></>}</div>
                    <div className='description'>
                        <h2>{data[j+3][i+1]}</h2>
                        <h3 className='title-name'>
                            {data[j][i+1]}
                            <span>
                                {(data[j + 4][i + 1]) ? <a href={data[j + 4][i + 1]} target="_blank" alt="redes"><i className="fab fa-facebook-f"></i></a> : ''}
                                {(data[j + 5][i + 1]) ? <a href={data[j + 5][i + 1]} target="_blank" alt="redes"><i className="fab fa-instagram"></i></a> : ''}
                                {(data[j + 6][i + 1]) ? <a href={data[j + 6][i + 1]} target="_blank" alt="redes"><FontAwesomeIcon icon={faTwitter} /></a> : ''}
                                {(data[j + 7][i + 1]) ? <a href={data[j + 7][i + 1]} target="_blank" alt="redes"><FontAwesomeIcon icon={faTiktok} /></a> : ''}
                            </span>
                        </h3>
                        <p>{ parse(data[j+2][i+1])}</p>
                    </div>
                </div>
            )
            i++
        }
        return  <AliceCarousel responsive={responsive} infinite={true}  mouseTracking controlsStrategy="alternate" items={row} />;
    }
    const ListadoNosotros = () => {
        let row = []
        var i = 1;
        var j = 48;
        while ( data[j][i] != undefined){
            row.push(
                <li className='question' key={i}>{data[j][i]}</li>
            )
            i++
        }
        return(
            <ul>
                {row}
            </ul>
        );
    }
    const RowsQuienesSomos = () => {
        let row = []
        for (var i = 28; i < 39; i+=2){
            if(data[i].length>0){
                row.push(
                    <div key={i} className='wrapper-4'>
                        <div className="one-4 content-text">
                            <a href={data[i][1]} >
                                {(data[i][0]) ? <img src={`https://drive.google.com/thumbnail?id=${data[i][0].split('/')[5]}&sz=w1000`} alt={"Logo"} width={"100%"} /> : <></>}
                            </a>
                        </div>
                        <div className="two-4 content-text">
                            <a href={data[i][1]} ><h3>{data[i][2]}</h3></a>
                        </div>
                        <div className="three-4 content-text">
                            <a href={data[i + 1][1]} >
                                {(data[i+1][0]) ? <img src={`https://drive.google.com/thumbnail?id=${data[i+1][0].split('/')[5]}&sz=w1000`} alt={"Logo"} width={"100%"} /> : <></>}
                            </a>
                        </div>
                        <div className="four-4 content-text">
                            <a href={data[i+1][1]} ><h3>{data[i+1][2]}</h3></a>
                        </div>
                    </div>)
            }
        }
        return row;
    }
    const Quotes = () => {
        let row = []
        var i = 0;
        var j = 77;
        const responsive = {
            0: { items: 1 },
            568: { items: 1 },
            1024: { items: 3 },
        };
        while ( data[j][i]!=undefined){
            row.push(
                <div key={i} className='content-quotes'>
                    <h2>{ data[j][i++]}</h2>
                    <p>{ data[j][i++]}</p>
                </div>
            )
        }
        return  <AliceCarousel responsive={responsive} infinite={true} disableDotsControls={true}  mouseTracking controlsStrategy="alternate" items={row} />;
    }

    const closeOthers = () => {
        let len = document.getElementsByClassName("Collapsible__trigger is-open").length
        for (let i = 0; i < len; i++){
            document.getElementsByClassName("Collapsible__trigger is-open")[i].click()
        }
    }
    const Faqs = () => {
        let row = []
        var i = 0;
        var j = 81;
        while ( data[j][i]!=undefined){
            row.push(
                <div className='flip-card col col-4' key={i}>
                    <p className='flip-card-inner'>
                        <div className="flip-card-front">
                            <span>{data[j][i]}</span>
                        </div>
                        <div className="flip-card-back bg-blue">
                            <p>{data[j][i+1]}</p>
                        </div>
                    </p>
                </div>
            )
            i+=2
        }
        return  row;
    }

    if (data.length < 1) {
        return (<div className='loading'>
            <img src={"https://lh3.googleusercontent.com/fife/AKsag4Pu7WWOS4BMTdT2PJ0I1gH71AW2FJJD7kh_th_9bQ7BNOycwWsW-locx_Ory35a1l3VWq37KnijR3BGNYRux-xoDiznYGpsGrSNbGVtiZr1kAiynd2X8XtpuZP1of1GCGZtbzq1_lfiyRYaGbhk-t25QnicB8ceWQaXzEiGDpsQqIZ4Ze03I7pRO_Zu5qZNpu5WFH7VTOK2u6V_IjrqoUv-EtdPnmWdJ1qdGyzFYlXeuuFsmVNBhECfKHOB1JI9Zf8fV7IKwEVIoHAyTFsZ66tEYZfh-x7nnoVbbA3DSBF9WaI7oeJdA57yi3F_MgXMgm-9ODBypFlLiEmNtNEGBNRcWZwzikqHaFtjJ6gQnWUeQc0zQr_WAdmo6LjcpL2yXuIVyKrtkUSb1FKTflNQTsK5dgREZa32xopN_-qEawC5Wo1rwpyifCiYtxfVSiuJzrikGVeSuxIgVOok4O7-XL5J5O5WMagKt0FDa9ppZ-Lh-1GQ0IWg9-RpXt9u1WSAdqtQxaj1qzvCpk5ndGPgX1JxwhvcOJLtaoj36LbQb1V74N2jhviDkPet-9n-CFkCjHgr-JzDP-D0n1zZwCEC79ROYshb_uM6lvUHXAwshLijW16WCHb0aP7j17BV5JYYPbwy1rQad9K0ytbbGxYZEFrbuxWWYbZ6_cuWQH5GHk3TKZsYAZG4JsKq5BvomC3l4gfRFFMlPmCqtE_XrcfvMH1egbz__zRxCnJlNge0Grpqp_lEX6ycPSYnmy74x-Cb1_GByTS_nJIPMvAacOJqmIXdJYq5_VZAgrsYo5B9nyTreW-IByORax4hxVaGusRaHi94Ln6KrLqQu8DMC0NowugpWpB7Ygc5BtyvE8W2fsfwZ49kdm1J9olN9DzJgBuTOud4Oetss9PZcRQcPc3BXzl_paHXOseOwiP2ctsBUOVVpzZybOYOvj3zxCOpbmtoFGUcaKo0Ljq5Fq-urnIcbGylm_MhSZdUO9WeT7rrFpB7kE_EA1AfpJDiTKrFXbYJpLH2GnYtOIO9KHPZE_2XRb9py_H5f6w3jqjDydXqVNSi3kCVdLEBPfb-sWnmVEGHCR9S4wZpUCOUXJ-Ft1zWtzgC4l5hXpOUnpMSXSJmHLITfLrS6BTrgSoYJhScAy_jx_hSJBvYMA0KoG27k4v6V4nZw7_sCMafTvtyHUAcm7jOCWtftbx0cVjPD3QOJDNS9LcIOX_6aCPDNE-29t8IoJw9kiya0cGtyhrWbLgK1NE5veabAWfYCFKYk7TTjCoBMIFvTMMUbIGDngBwFzsErZHeoU5vrV89rMwZuH6UDUfca9ybyIP14_dMDctytK2aJUAhm6qiauEWhQm4IqZldy3OLYof2opdw8rm2JOiFRdliw_BNon_2xJMOH4c-_N-KlIK5RdbjcG-Jh_dz8FagsGSSINjnDlw0XqhQrUgYFjskNEhTr8dfsytF7wn_MnfzHDgLTdc9zcb9XnaDGmuxAAGJC-_-VCBzf91HnrArUN21_FVoKuJnG0IVbNwhcK3jGvJLm_54FgbSvknoJbCnfH51MH1DPkahqttd-pS-w=w3024-h1732"} width="300" alt="Logo" title="Logo" />
            <Loading />
        </div>)
    }else {
        let background = `url('https://drive.google.com/thumbnail?id=${data[1][1].split('/')[5]}&sz=w1000')`
        let backgroundPorqueYo = `url('https://drive.google.com/thumbnail?id=${data[47][2].split('/')[5]}&sz=w1000')`
        return (
            <div className='container padding-top-0'>
                <div className='hero-container home' style={{ backgroundImage: background, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <h1>{(data[24][1]) && data[24][1]}</h1>
                </div>
                <h2 className='padding-bottom-40 padding-top-40 button-section-1'>
                    <a href={ data[26][2]} onClick={() => window.open(data[26][2],"_blank")}  className='button' target="_blank" rel='noreferrer'>{data[26][1] && data[26][1] }</a>
                </h2>
                <div className='hero-container container nuestro-productos' >
                    <div className={"title-box"}>
                        <div className={"row padding-bottom-30 border-bottom-1"}>
                            <h1 className='primary-color col-3-reverse'><b>{data[27][1]}</b></h1>
                            <h2 className='col-3 text-right'><a className={"button"} href={(data[33][2]) ? data[33][2] : '#'} >{data[33][1]}</a></h2>
                        </div>
                        <div className={"row"}>
                            <h3 className='subtitle padding-top-25'>{(data[25][1]) && data[25][1]}</h3>
                        </div>
                    </div>
                    <NuestrosProductos />
                    <h2 className='button' onClick={() => window.open(data[34][2],"_blank")}><a href={(data[34][2]) ? data[34][2] : '#'} >{data[34][1]}</a></h2>
                </div>
                <div className='hero-container conocenos' >
                    <div className={"title-box"}>
                        <h1 className='primary-color'>{data[36][1]}</h1>
                    </div>
                    <Conocenos />
                </div>
                <div className='title-bg' style={{ backgroundImage: backgroundPorqueYo, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <h1 className='title-over-image'>{data[47][1]}</h1>
                </div>
                <div className='hero-container-section listado-nosotros'>
                    <ListadoNosotros />
                    {data[47][4] && <h3 className='description-text'>{data[47][4]}</h3> }
                    <h2 className="button"><a href={data[49][2]} target='_blank'>{data[49][1]}</a></h2>
                </div>
                <div className='hero-container faqs' >
                    <div className='title-box'>
                        <h1>{data[79][1]}</h1>
                    </div>
                    <div className=''>
                        <div className='faqs-list row'>
                            <Faqs />
                        </div>
                    </div>
                </div>
                {/* <ModalFormInversion left={posicion.left} top={posicion.top} open={openModal} rangoInversion={rangoInversion} data={dataInversionSelected} closeFormInversion = {closeFormInversion}/> */}
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Aportación</DialogTitle>
                    <DialogContent>
                        <DialogContentText  style={{color: "red"}}>
                            {messageAlert}
                        </DialogContentText>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">¿En que punto consideras mas que te gustaría aportar y asociarte con Yodesarrollo?*</FormLabel>
                            <RadioGroup
                                autoFocus
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                onChange={(e)=>{
                                    let dat = dataForm;
                                    dat.question1= e.target.value
                                    setDataForm(dat)
                                }}
                            >
                                <FormControlLabel value="alysa" control={<Radio />} label="Casa Alysa, Altozano Hermosillo, Sonora, México." />
                                <FormControlLabel value="maria" control={<Radio />} label="Casa María, Altozano, Hermosillo, Sonora, México." />
                                <FormControlLabel value="aria" control={<Radio />} label="Casa Ária, Altozano, Hermosillo, Sonora, México." />
                                <FormControlLabel value="diversificado" control={<Radio />} label="Diversificado, el recurso sería distribuido entre dos o más propiedades." />
                                <FormControlLabel value="otra_ciudad" control={<Radio />} label="Me interesaría contribuir para abrir Yodesarrollo en otra ciudad." />
                                <FormControlLabel value="nueva_oportunidad" control={<Radio />} label="Me gustaría que se comunicaran cuando se tenga una nueva oportunidad." />
                            </RadioGroup>
                        </FormControl>
                        <br/><br/>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Montos de aportación y porcentajes de retorno*</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                onChange={(e)=>{
                                    let dat = dataForm;
                                    dat.question2= e.target.value
                                    setDataForm({...dataForm,question2: e.target.value})
                                }}
                            >
                                <FormControlLabel value="50k" control={<Radio />} label="Desde $50,000 MXN al 12% de retorno anual" />
                                <FormControlLabel value="100k" control={<Radio />} label="A partir de $100,000 MXN al 14% de retorno anual" />
                                <FormControlLabel value="250k" control={<Radio />} label="A partir de $250,000 MXN al 16% de retorno anual" />
                                <FormControlLabel value="350k" control={<Radio />} label="A partir de $350,000 MXN al 18% de retorno anual" />
                                <FormControlLabel value="500k" control={<Radio />} label="A partir de $500,000 MXN por el 20% de retorno anual" />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Nombre"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={(e)=>{
                                let dat = dataForm;
                                dat.name= e.target.value
                                setDataForm(dat)
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={(e)=>{
                                let dat = dataForm;
                                dat.email= e.target.value
                                setDataForm(dat)
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="phone"
                            label="Whatsapp"
                            type="phone"
                            fullWidth
                            variant="standard"
                            onChange={(e)=>{
                                let dat = dataForm;
                                dat.phone= e.target.value
                                setDataForm(dat)
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button onClick={() => sendEmail()}>Enviar</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    open={openSnack}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    message={messageAlert}
                    // action={action}
                />
            </div>
        )
    }
}

export default HeroSection