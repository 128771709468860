import React, { useState } from 'react'
import * as myConst from '../../constants.js';
import './HelperAvatar.css';

export default function HelperAvatar(props) {
    const [displayBox,setDisplayBox] = useState(false);
    const {data} = props
    // console.log((data[149]))
    if(data[149])
    return (
        <>
            {displayBox &&
                <div className='helperFrame'>
                    <div className='card'>
                        <div className='title'>
                            {data[150][1]}
                            <span onClick={() => setDisplayBox(false)}>
                                <i className="fas fa-times"></i>
                            </span>
                        </div>
                        <div className='body'>
                            <div className='col col-2 avatarProfile'>
                                <img src={"https://drive.google.com/thumbnail?id="+data[149][1].split('/')[5]} alt='Avatar' />
                            </div>
                            <div className='col col-2-reverse message'>
                                <div className='msg'>
                                    <div className='msgBox'>
                                        {data[151][1]}
                                    </div>
                                    <div className='col col-5'>
                                        <a href={"https://api.whatsapp.com/send?phone="+data[153][1]} target="_blank" className="whatsappButton"><i className="fab fa-whatsapp"></i>WhatsApp</a>
                                    </div>
                                    <div className='col col-5'>
                                        <a href={data[152][1]} target="_blank" className="messenger"><i className="fab fa-facebook-messenger"></i>Messenger</a>
                                    </div>
                                    <div className='col col-5'>
                                        <a href={'mailto:'+data[155][1]} target="_blank" className="email"><i className="far fa-envelope"></i>Email</a>
                                    </div>
                                    <div className='col col-5'>
                                        <a href={'tel:'+data[154][1]} target="_blank" className="phone"><i className="fas fa-phone"></i>Llamada</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>        
                </div>
            }
            <div className='helper'>
                <div className='avatar' onClick={() => setDisplayBox(true)}>
                    <img src={"https://drive.google.com/thumbnail?id="+data[149][1].split('/')[5]} alt="Avatar" />
                </div>
            </div>
        </>
    )
    else return <></>
}
