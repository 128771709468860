import React, { useEffect, useState } from 'react';
import './Modelo.css';
import {useParams} from 'react-router-dom';
import * as myConst from '../../../constants';

function Modelo() {
    const params = useParams();
    useEffect(() => {
        if(params.name!=="Alysa" && params.name!=="Aria"){
            setShowError(true)
        }
    }, []);

    const descripcion = {
        ciudad: (params.name==="Alysa")? 'Hermosillo' :'Hermosillo',
        colonia: (params.name==="Alysa")? 'Altozano' :'Altozano',
        privada: (params.name==="Alysa")? 'Conejo' :'Alondra',
        direccion: (params.name==="Alysa")? 'Valle Conejo Lote 13' :'Cumbre de la alondra Lote 12',
        precioPreventa: (params.name==="Alysa")? '$3,970,000.00' :'$7,306,329.60',
        precioVenta: (params.name==="Alysa")? '$4,287,600.00' :'$7,890,835.97',
        rentaMensual: (params.name==="Alysa")? '$24,500.57' :'$42,978.41',
        m2: (params.name==="Alysa")? '180' :'465',
        frenteMl: (params.name==="Alysa")? '9' :'15',
        fondoMl: (params.name==="Alysa")? '20' :'31',
        precioM2: (params.name==="Alysa")? '$6,000.00' :'$6,000.00',
        precioTerreno: (params.name==="Alysa")? '$1,080,000.00' :'$2,790,000.00',
        construidoHabitable: (params.name==="Alysa")? '180.85' :'278.84',
        construidoNoHabitable: (params.name==="Alysa")? '67.52' :'143.45',
        construidoNoTechado: (params.name==="Alysa")? '31.06' :'188.84',
        patio: (params.name==="Alysa")? '13.5' :'48.23',
        recamara1: (params.name==="Alysa")? '37.09m2 incluye: Baño + walking closet' :'56.42m2 incluye: Baño + walking closet',
        recamara2: (params.name==="Alysa")? '23.07m2 incluye: Baño' :'30.06m2 incluye: Baño + walking closet',
        recamara3: (params.name==="Alysa")? '12.75m2' :'28.86m2 Baño + walking closet',
        recamara4: (params.name==="Alysa")? '' :'30.78m2 incluye: Baño/Cocineta',
        baño3: (params.name==="Alysa")? '8.12' :'',
        medioBaño: (params.name==="Alysa")? '8.12' :'6.06',
        cocina: (params.name==="Alysa")? '15.56m2 incluye: alacena' :'23.04m2 incluye: alacena',
        salaComedor: (params.name==="Alysa")? '31.87' :'56.33',
        salaTV: (params.name==="Alysa")? '16.72' :'24.75',
        areaEscaleras: (params.name==="Alysa")? '27.55' :'22.54',
        terraza: (params.name==="Alysa")? '29.16' :'86.49',
        lavanderia: (params.name==="Alysa")? '10.92' :'14.5',
        cochera: (params.name==="Alysa")? '27.44m2 para 2 automóviles' :'42.46m2 para 3 automóviles',
        extras: (params.name==="Alysa")? 'Frente al Lago, Asador en terraza, Recámara en PB con preparación de accesibilidad' :'Terrazas con Vista a los lagos',
    }

    const imagesArq = {
        images: [
            myConst.BASE_URL+"/img/modelos/"+params.name+"/arquitectonico/1.png",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/arquitectonico/2.png"
        ],
        descriptions: [
            "Plano arquitectónico planta baja",
            "Plano arquitectónico planta alta",
        ]

    }
    const imagesExt = {
        images: [
            myConst.BASE_URL+"/img/modelos/"+params.name+"/exteriores/1.png",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/exteriores/2.png"
        ],
        descriptions: [
            "Fachada",
            (params.name==="Alysa")?"Fachada 2":"Facha Trasera",
        ]
    };
    const imagesInt = 
    {
        images: [
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/7.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/1.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/2.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/3.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/4.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/5.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/6.jpg",
            myConst.BASE_URL+"/img/modelos/"+params.name+"/interiores/8.jpg"
        ],
        descriptions: [
            (params.name==="Alysa")?"Vista entrada": '',
            (params.name==="Alysa")?"Recamara 1": '',
            (params.name==="Alysa")?"Estancia": '',
            (params.name==="Alysa")?"Sala": '',
            (params.name==="Alysa")?"Cocina": '',
            (params.name==="Alysa")?"Comedor": '',
            (params.name==="Alysa")?"Recamara principal": '',
            (params.name==="Alysa")?"Recamara 2": '',
        ]
    };

    const [index, setIndex] = useState(0);
    const [showError, setShowError] = useState(false);
    const [selectGallery, setSelectGallery] = useState();
    const [displayModal, setDisplayModal] = useState(false);
    
    const next = () => {
      setIndex((i) => (i + 1) % selectGallery.images.length);
    };
    const prev = () => {
      setIndex(
        (i) => (((i - 1) % selectGallery.images.length) + selectGallery.images.length) % selectGallery.images.length
      );
    };
    const onClickOutside = (e) => {
      if (e.target.localName !== "img" && e.target.localName !== "span") {
        setDisplayModal(false);
      }
    };

    if(showError)
        return (
            <div className='container'>
                <h1>La página no se encuentra disponible...</h1>
            </div>
        )
    else
    return (
        <div className='container'>
            <div className='row marginTop20'>
                <h1 className='primaryTitle'>MODELO {params.name}</h1>
            </div>
            <div className='row'>
                <div className='col col-3'>
                    <div className='verticalCardImage' onClick={() =>{setSelectGallery(imagesArq);setDisplayModal(true)}}>
                        <div className='textBox'>
                            <h2>Planos arquitectónicos</h2>
                            <p>Diseño y mediciones</p>
                        </div>
                        <img src={myConst.BASE_URL+"/img/modelos/"+params.name+"/bg_arquitectonico.png"} alt="planos arquitectonico" />
                    </div>
                </div>    
                <div className='col col-3'>
                    <div className='verticalCardImage' onClick={() =>{setSelectGallery(imagesExt);setDisplayModal(true)}}>
                        <div className='textBox'>
                            <h2>Diseño de exteriores</h2>
                            <p>Fachadas</p>
                        </div>
                        <img src={myConst.BASE_URL+"/img/modelos/"+params.name+"/bg_exterior.png"} alt="planos exteriores" />
                    </div>
                </div>    
                <div className='col col-3'>
                    <div className='verticalCardImage' onClick={() =>{setSelectGallery(imagesInt);setDisplayModal(true)}}>
                        <div className='textBox'>
                            <h2>Diseño de interiores</h2>
                            <p>Decoración y espacios</p>
                        </div>
                        <img src={myConst.BASE_URL+"/img/modelos/"+params.name+"/bg_interior.png"} alt="planos interiores" />
                    </div>
                </div>    
            </div>
            <div className='row marginTop20'>
                <h1 className='primaryTitle'>DETALLES</h1>
            </div>
            <div className='row'>
                <div className="col col-5">
                    <div className="card">
                        <ul className='list noBullets'>
                            <li><span className="col col-5 bgBlack">Ciudad:</span><span className="col col-5">{descripcion.ciudad} </span></li>
                            <li><span className="col col-5 bgBlack">Colonia:</span><span className="col col-5">	{descripcion.colonia}</span></li>
                            <li><span className="col col-5 bgBlack">Privada: </span><span className="col col-5"> {descripcion.privada}</span></li>
                            <li><span className="col col-5 bgBlack">Dirección</span><span className="col col-5">{descripcion.direccion}</span></li>
                            <li><span className="col col-5 bgBlack">Precio preventa:</span><span className="col col-5">	{descripcion.precioPreventa}</span></li>
                            <li><span className="col col-5 bgBlack">Precio venta final</span><span className="col col-5"> {descripcion.precioPreventa}</span></li>
                            <li><span className="col col-5 bgBlack">Renta estimada mensual</span><span className="col col-5"> {descripcion.rentaMensual}</span></li>
                            <li><span className="col col-5 bgBlack">Precio terreno</span><span className="col col-5"> {descripcion.precioTerreno}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-5">
                    <div className="card">
                        <ul className='list noBullets'>
                            <li><span className="col col-5 bgBlack">m2 terreno</span><span className="col col-5">{descripcion.m2}</span></li>
                            <li><span className="col col-5 bgBlack">Frente ml</span><span className="col col-5"> {descripcion.frenteMl}</span></li>
                            <li><span className="col col-5 bgBlack">Fondo ml</span><span className="col col-5"> {descripcion.fondoMl}</span></li>
                            <li><span className="col col-5 bgBlack">Precio m2</span><span className="col col-5">{descripcion.precioM2}</span></li>
                            <li><span className="col col-5 bgBlack">Construido habitable m2</span><span className="col col-5">{descripcion.construidoHabitable}</span></li>
                            <li><span className="col col-5 bgBlack">Construido no habitable m2</span><span className="col col-5">{descripcion.construidoNoHabitable}</span></li>
                            <li><span className="col col-5 bgBlack">Construido no techado </span><span className="col col-5">{descripcion.construidoNoTechado}</span></li>
                            <li><span className="col col-5 bgBlack">Patio </span><span className="col col-5">{descripcion.patio}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className="col col-5">
                    <div className="card">
                        <ul className='list noBullets'>
                            <li><span className="col col-5 bgBlack">Recámara 1:</span><span className="col col-5">{descripcion.recamara1} </span></li>
                            <li><span className="col col-5 bgBlack">Recámara 2:</span><span className="col col-5">	{descripcion.recamara2}</span></li>
                            <li><span className="col col-5 bgBlack">Recámara 3: </span><span className="col col-5"> {descripcion.recamara3}</span></li>
                            {descripcion.recamara4 && <li><span className="col col-5 bgBlack">Recámara 4:</span><span className="col col-5">{descripcion.recamara4}</span></li>}
                            {descripcion.baño3 && <li><span className="col col-5 bgBlack">Baño 3:</span><span className="col col-5">	{descripcion.baño3}</span></li>}
                            <li><span className="col col-5 bgBlack">1/2 Baño</span><span className="col col-5"> {descripcion.medioBaño}</span></li>
                            <li><span className="col col-5 bgBlack">Cocina</span><span className="col col-5"> {descripcion.cocina}</span></li>
                        </ul>
                    </div>
                </div>
                <div className="col col-5">
                    <div className="card">
                        <ul className='list noBullets'>
                            <li><span className="col col-5 bgBlack">Sala comedor</span><span className="col col-5"> {descripcion.salaComedor}</span></li>
                            <li><span className="col col-5 bgBlack">Sala TV</span><span className="col col-5">{descripcion.salaTV}</span></li>
                            <li><span className="col col-5 bgBlack">Área de Escaleras</span><span className="col col-5"> {descripcion.areaEscaleras}</span></li>
                            <li><span className="col col-5 bgBlack">Terraza(s)</span><span className="col col-5"> {descripcion.terraza}</span></li>
                            <li><span className="col col-5 bgBlack">Lavandería</span><span className="col col-5">{descripcion.lavanderia}</span></li>
                            <li><span className="col col-5 bgBlack">Cochera</span><span className="col col-5">{descripcion.cochera}</span></li>
                            <li><span className="col col-5 bgBlack">Extras</span><span className="col col-5">{descripcion.extras}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            {(params.name==="Alysa") && <>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>UBICACIÓN</h1>
                </div>
                <div className='row'>
                    <div className="col col-10">
                        <div className="card">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1863.975465145325!2d-111.07203009083094!3d29.067530212720715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce83722ea0b59f%3A0x90c81692bb92e38a!2sCasa%20Alysa!5e1!3m2!1ses-419!2smx!4v1640159492231!5m2!1ses-419!2smx" width="100%" height="450" loading="lazy" title="Mapa"></iframe>
                        </div>
                    </div>
                </div>
                </>
            }
            {displayModal && (
                <div className='modal' onClick={onClickOutside}>
                    <div className='header'>
                        <p>{selectGallery.descriptions[index]}</p>
                        <span onClick={() => setDisplayModal(false)}>
                            <i className="fas fa-times"></i>
                        </span>
                    </div>
                    <span className='btn-prev' onClick={prev}>&lt;</span>
                    <div className="wrapper">
                        <img src={selectGallery.images[index]} alt={selectGallery.descriptions[index]} />
                    </div>
                    <span className='btn-next' onClick={next}>&gt;</span>
                </div>
            )}
        </div>
    )
}

export default Modelo
