import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Navbar.css';

function Navbar({data}) {
    const [click,setClick] = useState(false);
    const [button,setButton] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false)
        }else{
            setButton(true)
        }
    }
    useEffect(() => {
        showButton();
      }, []);

    window.addEventListener('resize',showButton);

    const GetMenu = () => {
        let rows=[]
        for(let row=12;row<20;row++){
            if(data[row].length>0){
            rows.push(
                <li key={row} className='nav-item'>
                    <a to={data[row][1]!=null? data[row][1] : ''} className='nav-links' onClick={closeMobileMenu}>
                        {data[row][0]}
                    </a>
                    {(data[row][2]!=null)? 
                        <ul className='submenu'>
                            {data[row].map((i,ix) => {
                                if(ix!=0 && ix%2==0)
                                    if(data[ix]!=null)
                                        return (
                                            <li key={ix}>
                                                <a href={data[row][ix+1]!=null? data[row][ix+1] : ''} className='' onClick={closeMobileMenu}>
                                                    {data[row][ix]}
                                                </a>
                                            </li>
                                        )
                            })}
                        </ul>
                        : <></>
                    }
                </li>
            )}
        }
        return rows;
    }

    return (
        <>
            <nav className='navbar'>
                { (data.length>0) ?
                    <div className='navbar-container'>
                        <a to='/' className='navbar-logo' onClick={closeMobileMenu}>
                            <img src={`https://drive.google.com/thumbnail?id=${data[2][1].split('/')[5]}`} alt="YoDesarrollo" title = "yoDesarrollo" width="200" />
                        </a>
                        <div className='menu-icon' onClick={handleClick}>
                            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                        </div>
                        <ul className={click ? 'nav-menu nav-menu2 active' : 'nav-menu nav-menu2'}>
                            <GetMenu />
                            {/* <li className='nav-item'>
                                <Link to='/modelos' className='nav-links' onClick={closeMobileMenu}>
                                    Modelos
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/proyectos' className='nav-links' onClick={closeMobileMenu}>
                                    Proyectos
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to='/signup' className='nav-links-mobile' onClick={closeMobileMenu}>
                                    Registrarse
                                </Link>
                            </li> */}
                        </ul>
                        {/* {button && <Button buttonStyle={'btn--outline'}>Registrarse</Button>} */}
                    </div>
                    : <></>
                }
            </nav>
        </>
    )
            // }
}

export default Navbar